import React, { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../Utlils";
import axios from "axios";

const GlobalContextProvider = createContext();
export const useGlobalContext = () => {
  return useContext(GlobalContextProvider);
};
const GlobalContext = ({ children }) => {
  const [questionLibrary, setQuestionLibrary] = useState([]);
  //GET QUESTION LIBRARY:
  const getQuestionLibrary = async (e) => {
    let data = await axios.get(`${BASE_URL}/questionLibrary`);
    setQuestionLibrary(data.data);
  };
  useEffect(() => {
    getQuestionLibrary();
  }, []);
  const value = { questionLibrary };
  return (
    <GlobalContextProvider.Provider value={value}>
      {children}
    </GlobalContextProvider.Provider>
  );
};

export default GlobalContext;
