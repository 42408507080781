import axios from "axios";
// ! comment this in deployment
// export const BASE_URL = "http://localhost:8081/api/diy";

// ! uncomment this in deployment
export const BASE_URL = "https://apiservice.miratsquanto.com/api/diy";

// get current survey
export const getCurrentSurvey = async (surveyId) => {
  let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyId}`);
  // console.log("getCurrentSurvey=>", data.data);
  return data.data;
};

// set cookie
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// get cookie
export function getCookie1(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
