import React from "react";

const Loader = ({ className }) => {
  return (
    <div
      className="w-[25px] h-[25px] bg-transparent border-4 border-blue-700 border-t-transparent App-logo rounded-full"
      style={{ backgroundColor: "transparent !important" }}
    ></div>
  );
};

export default Loader;
