import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import GlobalContext from "./Contexts/GlobalContext";
import Loader from "./components/Loader";
const DIYStart = lazy(() => import("./Take-survey/DIYStart"));
const EncryptStart = lazy(() => import("./Take-survey/EncryptStart"));
const SurveyNotLive = lazy(() => import("./Take-survey/SurveyNotLive"));
const AddBlockerDetected = lazy(() => import("./pages/AddBlockerDetected"));
const EndPoint = lazy(() => import("./Take-survey/EndPoint"));
const EndPage = lazy(() => import("./Take-survey/EndPage"));
const GDPR = lazy(() => import("./Take-survey/GDPR"));
const SurveyQualification = lazy(() =>
  import("./Take-survey/SurveyQualification")
);
const LoaderComp = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};
function App() {
  return (
    <GlobalContext>
      <BrowserRouter>
        <Routes>
          <Route
            path="/DIY/v2/:surveyId/:encSupplierId/:orgId/start"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EncryptStart />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/lightningStart"
            element={
              <Suspense fallback={<LoaderComp />}>
                {" "}
                <DIYStart />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/gdpr-consent"
            element={
              <Suspense fallback={<LoaderComp />}>
                <GDPR />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/questions"
            element={
              <Suspense fallback={<LoaderComp />}>
                <SurveyQualification />
              </Suspense>
            }
          />
          <Route
            path="/7e08091a73b14e034889265e41ba796f91c766ad/:id/:status"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EndPage />
              </Suspense>
            }
          />
          <Route
            path="/21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EndPoint />
              </Suspense>
            }
          />
          <Route
            path="/error/:s"
            element={
              <Suspense fallback={<LoaderComp />}>
                <SurveyNotLive />
              </Suspense>
            }
          />
          <Route
            path="/error/add-blocker-detected"
            element={
              <Suspense fallback={<LoaderComp />}>
                <AddBlockerDetected />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </GlobalContext>
  );
}

export default App;
